/* eslint-disable */

/**
 * @file Manages backoffice settings
 */

import React, { useEffect, useState } from 'react';
import axios from "axios";
import styled, { css } from "styled-components";
import Switch from "react-switch";
import TimeField from 'react-simple-timefield';

import {
  Container,
  Wrapper,
  Section,
  Margin,
  H3,
  H4,
  H5,
  Text11,
  Text12,
  Text14,
  Text16,
  Loading,
  Checkbox,
} from '../atoms';

import {
  ModalNotification
} from '../molecules';

/**
 * @component Settings
 *
 * - Manages settings of:
 *   - Support Text  
 *   - Event languages
 *   - Last booking time
 *   - Email
 *
 * CALLED BY:
 * -
 *
 * TO IMPROVE:
 * @todo Create Separate components for:
 *   @todo Event Languages
 *   @todo Support text
 */
export default function Settings(props) {

  const useSemiPersistentState = (initialState) => {
    const [inputs, setInputs] = React.useState(
      localStorage.getItem('inputs') || initialState
    );
    React.useEffect(() => {
      localStorage.setItem('inputs', inputs);
    }, [inputs]);

    return [inputs, setInputs];    
  };  


/**
 * CONSTANTS AND VARIABLES
 *
 * TO IMPROVE:
 * @todo Complete comments
 */

  let bilheteiraId = sessionStorage.getItem('bilheteira');
  const BACKEND_DOMAIN = props.BACKEND_DOMAIN;


/**
 * STATE HOOKS
 * 
 * TO IMPROVE:
 * @todo Complete comments
 */

  const [token, setToken] = useState('')
  const [loading, setLoading] = useState(false)
  const [availableEmail, setAvailableEmail] = useState(false)
  const [availableInvoice, setAvailableInvoice] = useState(false)
  const [availableText, setAvailableText] = useState(false)
  const [qrcodeTickets, setQrcodeTickets] = useState(false)
  const [showLanguages, setShowLanguages] = useState(false)
  const [backupEmail, setBackupEmail] = useState(false)
  const [colorSystem, setColorSystem] = useState(false) 
  const [selectedLanguages, setSelectedLanguages] = useState([])
  const [useMailchimp, setUseMailchimp] = useState(false)
  const [mailchimpTemplates, setMailchimpTemplates] = useState(false)  
  const [inputs, setInputs] =  useSemiPersistentState({ 
    availableText: false,
    showLanguages: false,
    EN: false,
    PT: false,
    FR: false,
    ES: false,
    IT: false,
    DE: false, 
    qrcodeTickets:false,   
    colorSystem: false,      
    supportTextPT: '', 
    supportTextEN: '',
    supportTextFR: '',     
    nodemailerFrom: '', 
    nodemailerReply: '', 
    host: '', 
    port: '', 
    username: '', 
    password: '',
    limite_hour:'',
    cupons: false,
    observations: false, 
    invoicing:false,
    invoicexpressApiKey:'',
    invoicexpressAccountName:'',
    stripeKey: '',
    mailchimpEmailKey:'',
    mailchimpMarketingKey:'',
    mailchimpInterfaceKey:'',    
    backupEmail: '',
    sendBackupEmail: false,
    useMailchimp: false,
    mailchimpTemplates: false,
  });
  const [errorField, setErrorField] = useState('')
  const [firstConfig, setFirstConfig] = useState(true)
  const [notification, setNotification] = useState(false)
  const [statusEmail, setStatusEmail] = useState(false)
  const [changeGeneralSettings, setChangeGeneralSettings] = useState(true)   // Defines if general settings form fields can be edited; true = disabled fields
  const [changeEmailSettings, setChangeEmailSettings] = useState(true)       // Defines if email settings form fields can be edited; true = disabled fields
  const [changeInvoiceSettings, setChangeInvoiceSettings] = useState(true)   // Defines if invoicing settings form fields can be edited; true = disabled fields
  const [changePaymentSettings, setChangePaymentSettings] = useState(true)   // Defines if invoicing settings form fields can be edited; true = disabled fields 
  const [languageIndex, setLanguageIndex] = useState('')                     // Index of checked/unchecked language in selecteLanguages
  const [user, setUser] = useState('') 
  const [userProfile, setUserProfile] = useState('')

/**
 * OTHER HOOKS
 *
 * TO IMPROVE:
 * @todo Complete comments
 */

  useEffect(() => {
    getCredentials();
  }, [])

  useEffect(() => {
    let temp = sessionStorage.getItem('token');  
      
    getAvailabilities(temp,inputs.id);
  }, [inputs])


/**
 * HANDLERS / ? CALLBACKS?
 *
 * TO IMPROVE:
 * @todo Complete comments
 */

/**
 * @callback handleInputChange - Handles modal input changes
 *
 * CALLED BY:
 * - 
 *
 * TO IMPROVE:
 * - 
 */
  const handleInputChange = (event) => {
    console.log(event);

    event.persist();

    setInputs(inputs => ({ ...inputs, [event.target.name]: event.target.value }));
  }


/**
 * @callback handleLanguageCheck - Handles languages checked boxes events
 *
 * CALLED BY:
 * - When one language checkbox is clicked
 *
 * TO IMPROVE:
 * - Replace index use by id for reference
 */
  const handleLanguageCheck = (event) => {
    event.persist();

    console.log(event.target.value);

    setInputs({...inputs, [event.target.value]: !inputs[event.target.value]})
  }


/**
 * FUNCTIONS
 *
 * TO IMPROVE:
 * @todo ? Include setstates n the form of @state in function header comments?
 */

/**
 * @function getCredentials
 *
 * - Retrieves and sets token for backend access
 * - Calls function to get toggle buttons state from Strapi
 * - Calls function to get email configuration from Strapi 
 *
 * CALLED BY:
 * - On 1st render
 *
 * TO IMPROVE:
 * - 
 */
  const getCredentials = () => {
    let temp = sessionStorage.getItem('token');
    let tempUser = sessionStorage.getItem('username');
    let tempUserProfile = sessionStorage.getItem('user-profile');

    setToken(temp);
    setUser(tempUser);
    setUserProfile(tempUserProfile);  

    getSelectedLanguages(temp);
    getConfigSettings(temp);
    //getInvoiceSettings(temp);  
  }

/**
 * @function getAvailabilities - Retrieves toggle buttons state from Strapi
 *
 * CALLED BY:
 * - On 1st render
 *
 * TO IMPROVE:
 * @todo Make work for more than one configuration
 */
  const getAvailabilities = (key,id) => {
    axios
      .get(BACKEND_DOMAIN + "/bilheteiras/" + bilheteiraId + "/comEpoca" + '?_limit=-1', {
        headers: {
          Authorization: `Bearer ${key}`,
        }
      })
      .then(response => {
        console.log(response.data)

        setAvailableEmail(response.data.notificacoes)
      })
      .catch(error => {
      })

    axios
      .get(BACKEND_DOMAIN + "/configuracoes/" + id, {
        headers: {
          Authorization: `Bearer ${key}`,
        }
      })
      .then(response => {
        console.log(response.data)

        setQrcodeTickets(response.data.qrcodeTickets)
        setAvailableText(response.data.Mostrar_TextoBilheteira)
        setShowLanguages(response.data.show_eventLanguages)
        setColorSystem(response.data.show_colorSystem)
        setBackupEmail(response.data.sendBackupEmail)
        setUseMailchimp(response.data.useMailchimp)
        setMailchimpTemplates(response.data.mailchimpTemplates)
      })
      .catch(error => {
      })
  }

    
/**
 * @function getSelectedLanguage - Gets language selection status from Strapi
 *
 * CALLED BY:
 * - On 1st render
 *
 * TO IMPROVE:
 * - 
 */
  const getSelectedLanguages = key => {
    axios
      .get(BACKEND_DOMAIN + "/linguas/", {
        headers: {
          Authorization: `Bearer ${key}`,
        }
      })
      .then(response => {
        console.log(response);
        setSelectedLanguages(response.data);
      })   
      .catch(error => {
      });
  }


/**
 * @function checkLanguages - Checks languages on screen
 *
 * CALLED BY:
 * - On 1st render
 *
 * TO IMPROVE:
 * - 
 */
  const checkLanguages = (languageCode) => {
    //console.log(languageCode)

    if (languageCode == 'EN') return inputs.EN
    if (languageCode == 'PT') return inputs.PT
    if (languageCode == 'FR') return inputs.FR
    if (languageCode == 'ES') return inputs.ES
    if (languageCode == 'IT') return inputs.IT
    if (languageCode == 'DE') return inputs.DE
  }


/**
 * @function getConfigSettings - Gets email configuration from Strapi
 *
 * CALLED BY:
 * - On 1st render
 *
 * TO IMPROVE:
 * - 
 */
  const getConfigSettings = (key) => {
    axios
      .get(BACKEND_DOMAIN + '/configuracoes?bilheteira=' + bilheteiraId, {
        headers: {
          Authorization: `Bearer ${key}`,
        },
      })
      .then(response => {
        console.log(response.data)

        if (response.data.length >= 1) {
          setInputs(
            {
              ...inputs,
              id: response.data[0].id,
              availableText: response.data[0].Mostrar_TextoBilheteira,
              showLanguages: response.data[0].show_eventLanguages,
              EN: response.data[0].ingles,
              PT: response.data[0].portugues,
              FR: response.data[0].frances,
              ES: response.data[0].espanhol,
              IT: response.data[0].italiano,
              DE: response.data[0].alemao,
              qrcodeTickets: response.data[0].qrcodeTickets,
              colorSystem: response.data[0].show_colorSystem,      
              supportTextPT: response.data[0].textoBilheteiraPT,
              supportTextEN: response.data[0].textoBilheteiraEN,
              supportTextFR: response.data[0].textoBilheteiraFR,
              port: response.data[0].port,
              nodemailerFrom: response.data[0].nodemailer_default_from,
              host: response.data[0].host,
              nodemailerReply: response.data[0].nodemailer_default_replyto,
              username: response.data[0].username,
              password: response.data[0].password,
              limite_hour: response.data[0].limite_hour,
              cupons: response.data[0].cupoes,
              observations: response.data[0].observacoes,             
              limite_hour:response.data[0].limite_hour,
              invoicing: response.data[0].faturacao,
              invoicexpressApiKey: response.data[0].ix_api_key,
              invoicexpressAccountName: response.data[0].ix_account_name, 
              stripeKey: response.data[0].stripe_api_key,
              mailchimpEmailKey: response.data[0].mailchimp_transact_email_key,
              mailchimpMarketingKey: response.data[0].mailchimp_marketing_key,
              mailchimpInterfaceKey: response.data[0].mailchimp_interface_api_key, 
              backupEmail: response.data[0].backupEmail,
              sendBackupEmail: response.data[0].sendBackupEmail,
              useMailchimp: response.data[0].useMailchimp,
              mailchimpTemplates: response.data[0].mailchimpTemplates,
            });
          setFirstConfig(false);
        } else {
          setFirstConfig(true);
        }
        setLoading(false);

      })
      .catch(error => {
      });
  }


/**
 * @function getInvoiceSettings - Gets email configuration from Strapi
 *
 * @deprecated 20-02-2023
 *
 * CALLED BY:
 * - On 1st render
 *
 * TO IMPROVE:
 * - 
 */
  const getInvoiceSettings = (key) => {
    axios
      .get(BACKEND_DOMAIN + '/bilheteiras/' + bilheteiraId, {
        headers: {
          Authorization: `Bearer ${key}`,
        },
      })
      .then(response => {
        console.log(response)
        console.log(inputs)
        setInputs(
          {
            ...inputs,
            invoicexpressApiKey: response.data.ix_api_key,
            invoicexpressAccountName: response.data.ix_account_name
          });
        setLoading(false);
      })
      .catch(error => {
      });
  }  


/**
 * @function changeEmailAvailability - Manages email use toggle button
 *
 * CALLED BY:
 * - On 1st render
 *
 * TO IMPROVE:
 * - 
 */
  const changeEmailAvailability = (event) => {
    setAvailableEmail(event)
  }


/**
 * @function changeInvoiceAvailability - Manages email use toggle button
 *
 * CALLED BY:
 * - On 1st render
 *
 * TO IMPROVE:
 * - 
 */
  const changeInvoiceAvailability = (event) => {
    setInputs({...inputs, invoicing: event});
  }  


/**
 * @function changeTextAvailability - Manages support text use toggle button
 *
 * CALLED BY:
 * - On 1st render
 *
 * TO IMPROVE:
 * - 
 */
  const changeTextAvailability = (event) => {
    setInputs({...inputs, availableText: event});
  }

/**
 * @function changeQrcodeTickets - Manages color system toggle button
 *
 * CALLED BY:
 * - 
 *
 * TO IMPROVE:
 * - 
 */
  const changeQrcodeTickets = (event) => {
    setInputs({...inputs, qrcodeTickets: event});
  }

/**
 * @function changeColorSystem - Manages color system toggle button
 *
 * CALLED BY:
 * - 
 *
 * TO IMPROVE:
 * - 
 */
  const changeColorSystem = (event) => {
    setInputs({...inputs, colorSystem: event});
  }


/**
 * @function changeLanguagesAvailability - Manages event languages use toggle button
 *
 * CALLED BY:
 * - 
 *
 * TO IMPROVE:
 * - 
 */
  const changeLanguagesAvailability = (event) => {
    setInputs({...inputs, showLanguages: event});
  }


/**
 * @function changeSendBackupEmail - Manages event languages use toggle button
 *
 * CALLED BY:
 * - 
 *
 * TO IMPROVE:
 * - 
 */
  const changeSendBackupEmail = (event) => {
    setInputs({...inputs, sendBackupEmail: event})
  }


/**
 * @function changeUseMailchimp - Manages event languages use toggle button
 *
 * CALLED BY:
 * - 
 *
 * TO IMPROVE:
 * - 
 */
  const changeUseMailchimp = (event) => {
    setInputs({
      ...inputs, 
      useMailchimp: event, 
      mailchimpTemplates: false,
    })
  }


/**
 * @function changeMailchimpTemplates - Manages event languages use toggle button
 *
 * CALLED BY:
 * - 
 *
 * TO IMPROVE:
 * - 
 */
  const changeMailchimpTemplates = (event) => {
    setInputs({...inputs, mailchimpTemplates: event});
  }

/**
 * @function changeInputs - Manages checkout input fields
 *
 * CALLED BY:
 * - 
 *
 * TO IMPROVE:
 * - 
 */
  const changeInputs = (event, label) => {
    console.log(event)
    console.log(label)    

    if (label == "cupons") setInputs({...inputs, cupons: event});
    if (label == "observations") setInputs({...inputs, observations: event});
  }


/**
 * @function validate - ...
 *
 * CALLED BY:
 * - 
 *
 * TO IMPROVE:
 * - 
 */
  const validate = (inputs) => {
    let values = Object.values(inputs);
    let keys = Object.keys(inputs);
    let error = '';
    for (let i = 0; i < values.length; i++) {
      if (values[i].length === 0 || values[i] === '') {
        error = keys[i]
        break;
      }
      if (keys[i] === 'nodemailerReply') {
        let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (re.test(inputs.nodemailerReply)) {
          error = '';
        } else {
          error = keys[i]
          break;
        }
      }
      error = '';
    }
    setErrorField(error);
    if (error !== '') {
      return false;
    } else {
      return true;
    }
  }

/**
 * @function testEmail - ...
 *
 * CALLED BY:
 * - 
 *
 * TO IMPROVE:
 * - 
 */
  const testEmail = () => {
    axios.post(BACKEND_DOMAIN + "/bilheteiras/" + bilheteiraId + "/testaremail", '', {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    })
      .then(function (response) {
        setNotification(true);
        setStatusEmail(true);
        setTimeout(() => {
          setNotification(false);
        }, 1500);
      })
      .catch(function (error) {
        setNotification(true);
        setStatusEmail(false);
        setTimeout(() => {
          setNotification(false);
        }, 1500);
      })
  }


/**
 * @function sendForm - ...
 *
 * CALLED BY:
 * - 
 *
 * TO IMPROVE:
 * - 
 */
const sendForm = () => {
  saveConfigurations()
}


/**
 * @function saveConfigurations - ...
 *
 * CALLED BY:
 * - 
 *
 * TO IMPROVE:
 * - 
 */
  const saveConfigurations = async () => {
    console.log(inputs)

    let dataConfig = {
      Mostrar_TextoBilheteira: inputs.availableText,
      show_eventLanguages: inputs.showLanguages,
      ingles: inputs.EN,
      portugues: inputs.PT,
      frances: inputs.FR,
      espanhol: inputs.ES,
      italiano: inputs.IT,
      alemao: inputs.DE, 
      qrcodeTickets:inputs.qrcodeTickets,
      show_colorSystem: inputs.colorSystem,
      textoBilheteiraPT: inputs.supportTextPT ? inputs.supportTextPT : '',
      textoBilheteiraEN: inputs.supportTextEN ? inputs.supportTextEN : '',
      textoBilheteiraFR: inputs.supportTextFR ? inputs.supportTextFR : '',        
      port: inputs.port ? inputs.port : '',
      nodemailer_default_from: inputs.nodemailerFrom ? inputs.nodemailerFrom : '',
      host: inputs.host ? inputs.host : '',
      nodemailer_default_replyto: inputs.nodemailerReply ? inputs.nodemailerReply : '',
      username: inputs.username ? inputs.username : '',
      password: inputs.password ? inputs.password : '',
      bilheteira: bilheteiraId,
      limite_hour: inputs.limite_hour 
        ? inputs.limite_hour.includes('.000') 
        ? inputs.limite_hour : inputs.limite_hour.length === 8 
        ? inputs.limite_hour + '.000' 
        : inputs.limite_hour + ':00.000' 
        : '',
      cupoes: inputs.cupons,
      observacoes: inputs.observations, 
      faturacao: inputs.invoicing,     
      ix_api_key: inputs.invoicexpressApiKey ? inputs.invoicexpressApiKey : '',
      ix_account_name: inputs.invoicexpressAccountName ? inputs.invoicexpressAccountName : '',
      stripe_api_key: inputs.stripeKey ? inputs.stripeKey : '',       
      mailchimp_transact_email_key: inputs.mailchimpEmailKey ? inputs.mailchimpEmailKey : '',
      mailchimp_marketing_key: inputs.mailchimpMarketingKey ? inputs.mailchimpMarketingKey : '',
      mailchimp_interface_api_key: inputs.mailchimpInterfaceKey ? inputs.mailchimpInterfaceKey : '',      
      backupEmail: inputs.backupEmail ? inputs.backupEmail : '',
      sendBackupEmail: inputs.sendBackupEmail, 
      useMailchimp: inputs.useMailchimp, 
      mailchimpTemplates: inputs.mailchimpTemplates,             
    }
    if (firstConfig) {
      await axios
        .post(BACKEND_DOMAIN + '/configuracoes', dataConfig, {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        })
        .then(function (response) {
          setNotification(true);
          setStatusEmail(true);
          setTimeout(() => {
            setNotification(false);
          }, 1500);
          setChangeGeneralSettings(true)
        })
        .catch(function (error) {
          setNotification(true);
          setStatusEmail(false);
          setTimeout(() => {
            setNotification(false);
          }, 1500);
        })
    } else {
      await axios
        .put(BACKEND_DOMAIN + '/configuracoes/' + inputs.id, dataConfig, {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        })
        .then(function (response) {
          setNotification(true);
          setStatusEmail(true);
          setTimeout(() => {
            setNotification(false);
          }, 1500);
          setChangeGeneralSettings(true)
        })
        .catch(function (error) {
          setNotification(true);
          setStatusEmail(false);
          setTimeout(() => {
            setNotification(false);
          }, 1500);
        })
    }

    await axios
      .put(BACKEND_DOMAIN + "/bilheteiras/" + bilheteiraId, {
        notificacoes: availableEmail
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      })
      .then(function (response) {
        console.log(response)
      })
      .catch(function (error) {
        console.error(error)
      })

    setChangeEmailSettings(true)
  }

  return (
    <Section>
      {loading == true && <Loading isLoading={loading} />}      
      {loading == false &&
        <Wrapper>  
          <Container>        
            <H3 font='SFRegular' style={{ fontWeight: 'normal' }}>
              General Settings
            </H3>
            <Margin margin={1} />
            <ButtonAllReser 
              onClick={() => setChangeGeneralSettings(false)} 
              style={{ width: '82px', boxSizing: 'border-box' }}
            >
              Edit
            </ButtonAllReser>
            <Margin margin={5} />                                                         
            <H5 font='SFRegular' style={{ fontWeight: 'normal' }}>
              Support Text
            </H5>
            <Margin />         
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Toggle
                onChange={changeTextAvailability}
                offHandleColor="#EBEBEB"
                onHandleColor="#FFCE20"
                height={24}
                name='availableText'
                checked={inputs.availableText}
                disabled={changeGeneralSettings}
              />
              <TextActive
                color={inputs.availableText ? '#373737' : '#EBEBEB'}
              >
                Active
              </TextActive>
            </div>                    
            <Margin margin={2} />
            <DivCards>
              <ContainerConfig>
                  <DivConfig>
                    <Text11
                      color='#8B8B8B'
                      dangerouslySetInnerHTML={{ __html: 'SUPPORT TEXT PT' }}
                    />
                    <Margin />
                    <Input
                      onChange={handleInputChange}
                      type='text'
                      placeholder='support text in portuguese'
                      name='supportTextPT'
                      className={(errorField === 'supportTextPT') ? 'error' : ''}
                      value={inputs.supportTextPT}
                      disabled={changeGeneralSettings}
                    />
                  </DivConfig>
                  <DivConfig>
                    <Text11
                      color='#8B8B8B'
                      dangerouslySetInnerHTML={{ __html: 'SUPPORT TEXT EN' }}
                    />  
                    <Margin />                
                    <Input
                      type='text'
                      placeholder='support text in english'
                      name='supportTextEN'
                      className={(errorField === 'supportTextEN') ? 'error' : ''}
                      onChange={handleInputChange}
                      value={inputs.supportTextEN}
                      disabled={changeGeneralSettings}                      
                    />
                  </DivConfig>
                  <DivConfig>
                    <Text11
                      color='#8B8B8B'
                      dangerouslySetInnerHTML={{ __html: 'SUPPORT TEXT FR' }}
                    />  
                    <Margin />                
                    <Input
                      type='text'
                      placeholder='support text in french'
                      name='supportTextFR'
                      className={(errorField === 'supportTextFR') ? 'error' : ''}
                      onChange={handleInputChange}
                      value={inputs.supportTextFR}
                      disabled={changeGeneralSettings}                      
                    />
                  </DivConfig>
              </ContainerConfig>
            </DivCards>            
            <Margin margin={3} />
            <H5 font='SFRegular' style={{ fontWeight: 'normal' }}>
              Event Languages
            </H5>
            <Margin />           
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Toggle
                onChange={changeLanguagesAvailability}
                offHandleColor="#EBEBEB"
                onHandleColor="#FFCE20"
                height={24}
                checked={inputs.showLanguages}
                disabled={changeGeneralSettings}                
              />
              <TextActive
                color={inputs.showLanguages ? '#373737' : '#EBEBEB'}
              >
                Active
              </TextActive>
            </div>
            <Margin margin={2} />
            <DivCards>
              <ContainerConfig>
                  <DivConfig>
                    <Text11
                      color='#8B8B8B'
                      dangerouslySetInnerHTML={{ __html: 'EVENT LANGUAGES' }}
                    />
                    <Margin />

                    {console.log(selectedLanguages)}

                    {selectedLanguages.map(({id, ...selectedLanguages}) => (
                      <>
                      <Margin margin={1} />
                        <label>
                          <Checkbox
                            key={id}
                            type='checkbox'
                            name={selectedLanguages.Codigo}
                            className={(errorField === 'selectedLanguages.code') ? 'error' : ''}
                            onChange={handleLanguageCheck}
                            checked={checkLanguages(selectedLanguages.Codigo)}
                            value={selectedLanguages.Codigo}
                            disabled={changeGeneralSettings}                            
                          />
                          {selectedLanguages.nomeLingua_EN}
                        </label>
                      </>
                    ))}                                               
                  </DivConfig>
              </ContainerConfig>
            </DivCards>
            <Margin margin={3} />
            <H5 font='SFRegular' style={{ fontWeight: 'normal' }}>
              Color System
            </H5>
            <Margin />
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Toggle
                onChange={changeColorSystem}
                offHandleColor="#EBEBEB"
                onHandleColor="#FFCE20"
                height={24}
                checked={inputs.colorSystem}
                disabled={changeGeneralSettings}                
              />
              <TextActive
                color={inputs.colorSystem ? '#373737' : '#EBEBEB'}
              >
                Active
              </TextActive>
            </div>
            <Margin margin={5} />
            <H5 font='SFRegular' style={{ fontWeight: 'normal' }}>
              Deadline for same-day booking
            </H5>
            <DivCards>
              <ContainerConfig>
                <DivConfig>
                  <Margin />
                  <TimeField
                    name = 'limite_hour'
                    value = {inputs.limite_hour}
                    onChange = {handleInputChange}
                    disabled={changeGeneralSettings}   
                    style={{
                      width:'90px',
                      padding: '16px 24px',
                      border: '1px solid #EBEBEB',
                      borderRadius:'2px',
                      fontSize: '14px',
                      lineHeight: '16px',
                      color: '#373737',
                      boxSizing: 'border-box',                 
                    }}                 
                  />
                </DivConfig>
              </ContainerConfig>
              <Margin margin={3} />                       
              <H5 font='SFRegular' style={{ fontWeight: 'normal' }}>
                Checkout Input Fields
              </H5>
              <Margin />             
              <Text11
                color='#8B8B8B'
                dangerouslySetInnerHTML={{ __html: 'CUPONS' }}
              />
              <Margin />
              <DivConfig style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Toggle
                  offHandleColor="#EBEBEB"
                  onHandleColor="#FFCE20"
                  height={24}
                  name='cupons'
                  checked={inputs.cupons}
                  disabled={changeGeneralSettings}
                  onChange={(event) => changeInputs(event, 'cupons')}                    
                />
                <TextActive
                  color={inputs.cupons ? '#373737' : '#EBEBEB'}
                >
                  Active
                </TextActive>
              </DivConfig>               
              <Text11
                color='#8B8B8B'
                dangerouslySetInnerHTML={{ __html: 'SPECIAL REQUESTS' }}
              />
              <Margin />              
              <DivConfig style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>              
                <Toggle
                  offHandleColor="#EBEBEB"
                  onHandleColor="#FFCE20"
                  height={24}
                  name='observations'
                  checked={inputs.observations}
                  disabled={changeGeneralSettings}
                  onChange={(event) => changeInputs(event, 'observations')}                    
                />
                <TextActive
                  color={inputs.observations ? '#373737' : '#EBEBEB'}
                >
                  Active
                </TextActive>
              </DivConfig>
              <Margin margin={4} />              
              <DivEmails>
                {!changeGeneralSettings &&
                    <div>
                      <ButtonAllReser 
                        onClick={() => sendForm()}                     
                        style={{ 
                        display: 'inline-block', 
                        width: '82px', 
                        marginRight: '10px', 
                        textAlign: 'center',
                        boxSizing: 'border-box' 
                      }}>
                      Save
                    </ButtonAllReser>
                    <ButtonAllReser 
                      onClick={() => setChangeGeneralSettings(true)} 
                      style={{ 
                        display: 'inline-block', 
                        background: '#EBEBEB', 
                        width: '82px', 
                        boxSizing: 'border-box' 
                      }}>
                      Cancel
                    </ButtonAllReser> 
                  </div>              
                }                 
              </DivEmails>
            </DivCards>           
          </Container>
          <Margin margin={5} />            
          <Container>
            <H3 font='SFRegular' style={{ fontWeight: 'normal' }}>
              Email Settings
            </H3>
            <Margin margin={1} />            
            <ButtonAllReser 
              onClick={() => setChangeEmailSettings(false)} 
              style={{ 
                width: '82px', 
                boxSizing: 'border-box' 
            }}>
              Edit
            </ButtonAllReser>
            <Margin margin={5} />             
            <H5 font='SFRegular' style={{ fontWeight: 'normal' }}>
              QR Code Tickets in Email
            </H5>
            <Margin />         
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Toggle
                onChange={changeQrcodeTickets}
                offHandleColor="#EBEBEB"
                onHandleColor="#FFCE20"
                height={24}
                name='availableText'
                checked={inputs.qrcodeTickets}
                disabled={changeEmailSettings}
              />
              <TextActive
                color={inputs.qrcodeTickets ? '#373737' : '#EBEBEB'}
              >
                Active
              </TextActive>
            </div>                                  
            <Margin margin={5} />                                   
            <H5 font='SFRegular' style={{ fontWeight: 'normal' }}>
              Send Notification Emails
            </H5>    
            <Margin />                    
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Toggle
                onChange={changeEmailAvailability}
                offHandleColor="#EBEBEB"
                onHandleColor="#FFCE20"
                height={24}
                checked={availableEmail}
                disabled={changeEmailSettings}
              />
              <TextActive
                color={availableEmail ? '#373737' : '#EBEBEB'}
              >
                Active
              </TextActive>
            </div>
            <Margin margin={5} />            
            <Text16>
              Credentials to use own server to send emails:
            </Text16>
            <Margin margin={2} />
            <DivCards> 
              <ContainerConfig> 
                <DivConfig>
                  <Text11
                    color='#8B8B8B'
                    dangerouslySetInnerHTML={{ __html: 'NODEMAILER DEFAULT FROM' }}
                  />
                  <Margin />
                  <Input
                    type='text'
                    placeholder='email'
                    name='nodemailerFrom'
                    className={(errorField === 'nodemailerFrom') ? 'error' : ''}
                    onChange={handleInputChange}
                    value={inputs.nodemailerFrom}
                    disabled={changeEmailSettings}                    
                  />
                </DivConfig>            
                <DivConfig>
                  <Text11
                    color='#8B8B8B'
                    dangerouslySetInnerHTML={{ __html: 'NODEMAILER DEFAULT REPLY-TO' }}
                  />
                  <Margin />
                  <Input
                    type='email'
                    placeholder='email'
                    name='nodemailerReply'
                    className={(errorField === 'nodemailerReply') ? 'error' : ''}
                    onChange={handleInputChange}
                    value={inputs.nodemailerReply}
                    disabled={changeEmailSettings}                    
                  />
                </DivConfig>
                <DivConfig>
                  <Text11
                    color='#8B8B8B'
                    dangerouslySetInnerHTML={{ __html: 'HOST' }}
                  />
                  <Margin />
                  <Input
                    type='text'
                    placeholder='Host'
                    name='host'
                    className={(errorField === 'host') ? 'error' : ''}
                    onChange={handleInputChange}
                    value={inputs.host}
                    disabled={changeEmailSettings}                                        
                  />
                </DivConfig>
                <DivConfig>
                  <Text11
                    color='#8B8B8B'
                    dangerouslySetInnerHTML={{ __html: 'PORT' }}
                  />
                  <Margin />
                  <Input
                    type='number'
                    placeholder='Port'
                    name='port'
                    className={(errorField === 'port') ? 'error' : ''}
                    onChange={handleInputChange}
                    value={inputs.port}
                    disabled={changeEmailSettings}                                        
                  />
                </DivConfig>
                <DivConfig>
                  <Text11
                    color='#8B8B8B'
                    dangerouslySetInnerHTML={{ __html: 'USERNAME' }}
                  />
                  <Margin />
                  <Input
                    type='text'
                    placeholder='Username'
                    name='username'
                    className={(errorField === 'username') ? 'error' : ''}
                    onChange={handleInputChange}
                    value={inputs.username}
                    disabled={changeEmailSettings}                                        
                  />
                </DivConfig>
                <DivConfig>
                  <Text11
                    color='#8B8B8B'
                    dangerouslySetInnerHTML={{ __html: 'PASSWORD' }}
                  />
                  <Margin />
                  <Input
                    type='password'
                    placeholder='Password'
                    name='password'
                    className={(errorField === 'password') ? 'error' : ''}
                    onChange={handleInputChange}
                    value={inputs.password}
                    disabled={changeEmailSettings}                                        
                  />
                </DivConfig>
              </ContainerConfig>
            </DivCards>
              <ButtonAllReser onClick={() => testEmail()} testEmail={true} style={{ boxSizing: 'border-box' }}>
                Send an email to your account's email address to confirm that everything is configured correctly.
              </ButtonAllReser>                
            <Margin margin={5} />                       
            <H5 font='SFRegular' style={{ fontWeight: 'normal' }}>
              Use Mailchimp
            </H5>    
            <Margin  margin={2}  />                    
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Toggle
                onChange={changeUseMailchimp}
                offHandleColor="#EBEBEB"
                onHandleColor="#FFCE20"
                height={24}
                checked={inputs.useMailchimp}
                disabled={changeEmailSettings}
              />
              <TextActive
                color={inputs.useMailchimp ? '#373737' : '#EBEBEB'}
              >
                Active
              </TextActive>
            </div>
            <Margin margin={5} />            
            <Text16>
              Credentials to use Mailchimp server to send emails:
            </Text16>
            <Margin margin={2} />      
            <DivCards>
              <ContainerConfig>
                <DivConfig>
                  <Text11
                    color='#8B8B8B'
                    dangerouslySetInnerHTML={{ __html: 'FROM EMAIL' }}
                  />
                  <Margin />
                  <Input
                    type='text'
                    placeholder='From Email'
                    name='nodemailerFrom'
                    className={(errorField === 'nodemailerFrom') ? 'error' : ''}
                    onChange={handleInputChange}
                    value={inputs.nodemailerFrom}
                    disabled={changeEmailSettings}                    
                  />
                </DivConfig>              
                <DivConfig>
                  <Text11
                    color='#8B8B8B'
                    dangerouslySetInnerHTML={{ __html: 'MAILCHIMP INTERFACE API KEY'}}
                  />
                  <Margin />
                  <Input
                    type='text'
                    placeholder='Mailchimp Interface API Key'
                    name='mailchimpInterfaceKey'
                    className={(errorField === 'mailchimpInterfaceKey') ? 'error' : ''}
                    onChange={handleInputChange}
                    value={inputs.mailchimpInterfaceKey}
                    disabled={changeEmailSettings}                    
                  />
                </DivConfig>                
              </ContainerConfig>
            </DivCards>
            <Text16>
              Choose between the backoffice or the Mailchimp templates, to be used when sending emails:
            </Text16>
            <Margin margin={2} />              
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <TextActive
                color={!inputs.mailchimpTemplates ? '#373737' : '#EBEBEB'}
                style={{marginRight: '5px'}}
              >
                Using Backoffice templates
              </TextActive>
              <Toggle
                onChange={changeMailchimpTemplates}
                offHandleColor="#EBEBEB"
                onHandleColor="#FFCE20"
                height={24}
                checked={inputs.mailchimpTemplates}
                disabled={!inputs.useMailchimp}
              />
              <TextActive
                color={inputs.mailchimpTemplates ? '#373737' : '#EBEBEB'}
              >
                Using Mailchimp templates
              </TextActive>
            </div>
            <Margin margin={5} />
            <H5 font='SFRegular' style={{ fontWeight: 'normal' }}>
              Send Backup Email
            </H5>    
            <Margin />          
            <DivConfig style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Toggle
                onChange={changeSendBackupEmail}
                offHandleColor="#EBEBEB"
                onHandleColor="#FFCE20"
                height={24}
                checked={inputs.sendBackupEmail}
                disabled={changeEmailSettings}
              />
              <TextActive
                color={inputs.sendBackupEmail ? '#373737' : '#EBEBEB'}
              >
                Active
              </TextActive>
            </DivConfig>
            <DivConfig>
              <Text11
                color='#8B8B8B'
                dangerouslySetInnerHTML={{ __html: 'BACKUP EMAIL'}}
              />
              <Margin />
              <Input
                type='text'
                placeholder='Backup Email'
                name='backupEmail'
                className={(errorField === 'backupEmail') ? 'error' : ''}
                onChange={handleInputChange}
                value={inputs.backupEmail}
                disabled={changeEmailSettings}                    
              />
            </DivConfig>                   
            <Margin margin={5} />    
            <DivEmails>                 
              {(!changeEmailSettings) &&                
                <div>
                  <ButtonAllReser 
                    onClick={() => sendForm()}                     
                    style={{ 
                      display: 'inline-block', 
                      width: '82px', 
                      marginRight: '10px', 
                      textAlign: 'center',
                      boxSizing: 'border-box' 
                    }}>
                    Save
                  </ButtonAllReser>
                  <ButtonAllReser 
                    onClick={() => setChangeEmailSettings(true)} 
                    style={{ 
                      display: 'inline-block', 
                      background: '#EBEBEB', 
                      width: '82px', 
                      boxSizing: 'border-box' 
                    }}>
                    Cancel
                  </ButtonAllReser> 
                </div>              
              }
            </DivEmails>                
          </Container>
          <Margin margin={5} />                     
          <Container>            
            <H3 font='SFRegular' style={{ fontWeight: 'normal' }}>
              Payment Settings
            </H3>
            <Margin margin={1} />              
            <DivEmails>  
              <div>         
                <ButtonAllReser 
                  onClick={() => setChangePaymentSettings(false)} 
                  style={{ 
                    width: '82px', 
                    boxSizing: 'border-box' 
                }}>
                  Edit
                </ButtonAllReser>
              </div>
            </DivEmails> 
            <Margin margin={5} />                          
            <DivCards>
              <ContainerConfig>
                <DivConfig>
                  <Text11
                    color='#8B8B8B'
                    dangerouslySetInnerHTML={{ __html: 'STRIPE KEY' }}
                  />
                  <Margin />
                  <Input
                    type='text'
                    placeholder='stripe key'
                    name='stripeKey'
                    className={(errorField === 'stripeKey') ? 'error' : ''}
                    onChange={handleInputChange}
                    value={inputs.stripeKey}
                    disabled={changePaymentSettings}                    
                  />
                </DivConfig>              
              </ContainerConfig>
              <Margin />
              <Margin margin={4}/>              
              {!changePaymentSettings &&
                <DivEmails>  
                  <div>
                    <ButtonAllReser 
                      onClick={() => sendForm()}                     
                      style={{ 
                        display: 'inline-block', 
                        width: '82px', 
                        marginRight: '10px', 
                        textAlign: 'center',
                        boxSizing: 'border-box' 
                      }}
                    >
                      Save
                    </ButtonAllReser>
                    <ButtonAllReser 
                      onClick={() => setChangePaymentSettings(true)} 
                      style={{ 
                        display: 'inline-block',  
                        width: '82px', 
                        boxSizing: 'border-box' 
                      }}
                    >
                      Cancel
                    </ButtonAllReser> 
                  </div>                             
                </DivEmails> 
              }                          
            </DivCards> 
          </Container> 
          <Margin margin={5} />            
          <Container>            
            <H3 font='SFRegular' style={{ fontWeight: 'normal' }}>
              Invoicing Settings
            </H3>
            <Margin margin={1} />              
            <DivEmails>  
              <div>         
                <ButtonAllReser 
                  onClick={() => setChangeInvoiceSettings(false)} 
                  style={{ 
                    width: '82px', 
                    boxSizing: 'border-box' 
                }}>
                  Edit
                </ButtonAllReser>
              </div>
            </DivEmails> 
            <Margin margin={5} />  
            {/*<H5 font='SFRegular' style={{ fontWeight: 'normal' }}>
              Invoicing
            </H5>  
            <Margin margin={1} />             
            <Margin />                    
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Toggle
                onChange={changeInvoiceAvailability}
                offHandleColor="#EBEBEB"
                onHandleColor="#FFCE20"
                height={24}
                name='invoicing'
                checked={inputs.invoicing}
                disabled={changeInvoiceSettings}
              />             
              <TextActive
                color={inputs.invoicing ? '#373737' : '#EBEBEB'}
              >
                Active
              </TextActive>
            </div>               
            <Margin margin={4} />*/}                                 
            <DivCards>
              <ContainerConfig>
                <DivConfig>
                  <Text11
                    color='#8B8B8B'
                    dangerouslySetInnerHTML={{ __html: 'INVOICE EXPRESS ACCOUNT NAME' }}
                  />
                  <Margin />
                  <Input
                    type='text'
                    placeholder='invoice express account name'
                    name='invoicexpressAccountName'
                    className={(errorField === 'invoicexpressAccountName') ? 'error' : ''}
                    onChange={handleInputChange}
                    value={inputs.invoicexpressAccountName}
                    disabled={changeInvoiceSettings}                    
                  />
                </DivConfig>              
                <DivConfig>
                  <Text11
                    color='#8B8B8B'
                    dangerouslySetInnerHTML={{ __html: 'INVOICE EXPRESS API KEY' }}
                  />
                  <Margin />
                  <Input
                    type='text'
                    placeholder='invoice express api key'
                    name='invoicexpressApiKey'
                    className={(errorField === 'invoicexpressApiKey') ? 'error' : ''}
                    onChange={handleInputChange}
                    value={inputs.invoicexpressApiKey}
                    disabled={changeInvoiceSettings}                    
                  />
                </DivConfig>
              </ContainerConfig>
              <Margin />
              <div>                 
                <ButtonAllReser 
                  onClick={() => window.open ('https://www.app.invoicexpress.com/', '_blank')} 
                  style={{ 
                    display: 'inline-block', 
                    test: '#EBEBEB', 
                    width: '250px', 
                    boxSizing: 'border-box' 
                  }}
                  testEmail={true}
                >
                  Sign up to your InvoiceXpress account
                </ButtonAllReser> 
              </div> 
              <Margin margin={4}/>              
              {!changeInvoiceSettings &&
                <DivEmails>  
                  <div>
                    <ButtonAllReser 
                      onClick={() => sendForm()}                     
                      style={{ 
                        display: 'inline-block', 
                        width: '82px', 
                        marginRight: '10px', 
                        textAlign: 'center',
                        boxSizing: 'border-box' 
                      }}
                    >
                      Save
                    </ButtonAllReser>
                    <ButtonAllReser 
                      onClick={() => setChangeInvoiceSettings(true)} 
                      style={{ 
                        display: 'inline-block',  
                        width: '82px', 
                        boxSizing: 'border-box' 
                      }}
                    >
                      Cancel
                    </ButtonAllReser> 
                  </div>                             
                </DivEmails> 
              }                          
            </DivCards> 
          </Container>                   
          <ModalNotification
            isOpen={notification}
            status={statusEmail}
            user={user}
            setUser={setUser}
            setUserProfile={setUserProfile}
            userProfile={userProfile}             
          />
        </Wrapper>
      }
    </Section>
  )
}

/**
 * @styles
 *
 * TO IMPROVE:
 * -
 */

const DivCards = styled.div`
  display:flex;
  flex-flow:column;
  margin-bottom: 20px;
  :last-child {
    margin-bottom:0;
  }
`;
const ButtonAllReser = styled.div`
  background: ${({ testEmail }) => testEmail ? '#EBEBEB' : '#FFCE20'};
  border-radius: 2px;
  padding: 8px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: SFRegular;
  font-weight: 500;
  font-size: 11px;
  line-height: 20px;
  width: ${({ testEmail }) => testEmail ? '560px' : '83px'};
  color: #373737;
  transition: all 0.4s ease;
  max-width:100%;
  :hover{
    background: ${({ testEmail }) => testEmail ? '#A9A9A9' : '#F8C100'};
    cursor: pointer;
  }
  :first-child{
    margin-bottom:16px;
  }
`;
const Toggle = styled(Switch)`
  .react-switch-bg{
    background-color:#fff !important;
    box-sizing: border-box;
    border: 1px solid #EBEBEB;
  }
`;
const TextActive = styled(Text11)`
  margin-left: calc(4px + (10 - 4) * ((100vw - 375px) / (1920 - 375)));
  text-transform: uppercase;
`;
const Input = styled.input`
  font-family: SFMedium;
  margin-bottom:8px;
  padding: 16px 24px;
  border: 1px solid #EBEBEB;
  border-radius: 2px;
  width: ${({ width }) => width ? width : ''}px;
  max-width: ${({ maxWidth }) => maxWidth ? maxWidth + 'px' : '100%'};
  margin-bottom: ${({ marginBottom }) => marginBottom ? marginBottom : '0'}px;
  font-size: 14px;
  line-height: 16px;
  color: #373737;
  box-sizing:border-box;
  transition: all 0.5s ease;
  ::placeholder{
    transition: all 0.5s ease;
    color: #A9A9A9;
  }
  :focus{
    background: #F9F9F9;
    outline:none;
  }
  &.error{
    border-color:#E3000F;
  }
  ${({ blocked }) => blocked && css`
    pointer-events:none;
    ::placeholder{
      color: #EBEBEB;
    }
  `}
  ${({ enabled }) => enabled && css`
    color: #FFCE20;
    border: 1px solid #FFCE20;
    background-color:rgba(255, 206, 32, 0.3);;
    ::placeholder{
      color: #FFCE20;
    }
  `}
`;

const InputCheckbox = styled(Input)`
  margin-bottom: 5px;
`

const ContainerConfig = styled.div`
  display:flex;
  flex-flow:row;
  flex-wrap:wrap;
  margin-right:-20px;
`;

const DivConfig = styled.div`
  width: calc(33% - 20px);
  display:flex;
  flex-flow:column;
  margin-right:20px;
  margin-bottom:16px;
  :last-child {
    margin-right:0;
  }
  @media screen and (max-width:850px){
    width: calc(50% - 20px);
  }
  @media screen and (max-width:600px){
    width: calc(100% - 20px);
  }
`;
const DivEmails = styled.div`
  display:flex;
  flex-flow: column;
`;